import { render, staticRenderFns } from "./TuiKuanJiLuDetail.vue?vue&type=template&id=277adc0e&scoped=true&"
import script from "./TuiKuanJiLuDetail.vue?vue&type=script&lang=js&"
export * from "./TuiKuanJiLuDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "277adc0e",
  null
  
)

export default component.exports