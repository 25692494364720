<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="支付订单号" prop="zhiFuDingDH" >
                <el-input v-model="form.zhiFuDingDH" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="姓名" prop="xingMing" >
                <el-input v-model="form.xingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="卡号" prop="kaHao" >
                <el-input v-model="form.kaHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="退款时间" prop="tuiKuanSJ">
                <el-date-picker v-model="form.tuiKuanSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="退款金额" prop="tuiKuanJinE" >
                <el-input v-model="form.tuiKuanJinE" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="操作人" prop="caoZuoRen" >
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/TuiKuanJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                zhiFuDingDH: [], // 支付订单号
                xingMing: [], // 姓名
                kaHao: [], // 卡号
                tuiKuanSJ: [], // 退款时间
                tuiKuanJinE: [], // 退款金额
                caoZuoRen: [], // 操作人
            }
            this.titlePrefix = '退款记录';
            this.defaultForm = {
                id: null,
                zhiFuDingDH: "", // 支付订单号
                xingMing: "", // 姓名
                kaHao: "", // 卡号
                tuiKuanSJ: "", // 退款时间
                tuiKuanJinE: "", // 退款金额
                caoZuoRen: "", // 操作人
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>