<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="姓名" >
                <el-input v-model="search.xingMing" size="small" />
            </x-search-item>
            <x-search-item label="退款时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.tuiKuanSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.tuiKuanSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>

        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column prop="zhiFuDingDH" label="支付订单号" />
            <el-table-column prop="xingMing" label="姓名" />
            <el-table-column prop="kaHao" label="卡号" />
            <el-table-column prop="tuiKuanSJ" label="退款时间" />
            <el-table-column prop="tuiKuanJinE" label="退款金额" />
            <el-table-column prop="caoZuoRen" label="操作人" />
<!--            <el-table-column width="250" align="center" label="操作" fixed="right">-->
<!--                <template slot-scope="{ row, $index }">-->
<!--                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/TuiKuanJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/tkjl/TuiKuanJiLuEdit";
    import Detail from "@/view/hygl/tkjl/TuiKuanJiLuDetail";

    export default {
        name: "TuiKuanJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    xingMing: '',
                    tuiKuanSJBegin: '',
                    tuiKuanSJEnd: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>
