<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="zhiFuDingDH" label="支付订单号" />
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="tuiKuanSJ" label="退款时间" />
            <x-detail-item prop="tuiKuanJinE" label="退款金额" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/TuiKuanJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                zhiFuDingDH: "", // 支付订单号
                xingMing: "", // 姓名
                kaHao: "", // 卡号
                tuiKuanSJ: "", // 退款时间
                tuiKuanJinE: "", // 退款金额
                caoZuoRen: "", // 操作人
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>