import { render, staticRenderFns } from "./TuiKuanJiLuList.vue?vue&type=template&id=4ed9822c&scoped=true&"
import script from "./TuiKuanJiLuList.vue?vue&type=script&lang=js&"
export * from "./TuiKuanJiLuList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed9822c",
  null
  
)

export default component.exports